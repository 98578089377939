<template>
  <f7-page no-toolbar no-swipeback name="financial" class="page-center" @page:init="onPageInit">
    <f7-navbar innerClass="navbar-inner">
      <f7-nav-left>
        <f7-link @click="$f7router.navigate('/home/')">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("luckbox_001") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="content">
      <div v-show="rule.Enable" @click="openRule()" class="rule">{{ $t("luckbox_022") }}</div>
      <div class="dan">
        <div class="bigbox" id="ballMachine">
          <!-- <div><img src="../../static/images/yt999/box/quan.png" alt="" srcset="" class="quan" /></div> -->
        </div>
        <div><img src="../../static/images/yt999/box/yin.png" alt="" srcset="" class="yin" /></div>
        <div class="yu">
          {{ $t("luckbox_002") }}<br />
          （<span style="color: red">{{ remaining }}</span>）
        </div>
        <div class="open" >
          <div v-show="showbox">
            <img class="showbox" src="../../static/images/yt999/box/kk.png" alt="" srcset="">
            <img class="zhong" v-show="zhong ==0" src="../../static/images/yt999/box/love.gif" alt="" srcset="">
          </div>
          <div v-show="showbox">
            <img class="showbox" src="../../static/images/yt999/box/kk.png" alt="" srcset="">
            <img class="zhong" v-show="zhong ==1" src="../../static/images/yt999/box/love.gif" alt="" srcset="">
          </div>
          <div v-show="showbox">
            <img class="showbox" src="../../static/images/yt999/box/kk.png" alt="" srcset="">
            <img class="zhong" v-show="zhong ==2" src="../../static/images/yt999/box/love.gif" alt="" srcset="">
          </div>
        </div>
        <div style="margin-top: -62px;">
          <button v-if="openbtn" @click="kaiqiu()" class="go">{{ $t("luckbox_021") }}</button>
          <button v-else @click="getluckydrawbox(false)" :class="clickFlag ? 'go' : 'nogo'">{{ $t("luckbox_003")}}</button>
        </div>
        <!-- <div class="fen">{{ $t("luckbox_004") }}</div> -->
        <!-- <div class="mian" @click="Ngo?getluckydrawbox(true):''">{{ $t("luckbox_005") }}</div> -->
        <div class="mian" @click="getluckydrawbox(true)">{{ $t("luckbox_005") }}</div>
      </div>
    </div>
    <van-tabs class="van" @change="onTabChange">
      <van-tab :title="$t('luckbox_010')" style="font-weight: bold">
        <ul style="padding: 0 10px">
          <li class="title">
            <div style="width: 33%">{{ $t("luckbox_011") }}</div>
            <div style="width: 33%">{{ $t("luckbox_029") }}</div>
            <div style="width: 33%;">{{ $t("luckbox_012") }}</div>
          </li>
          <li v-for="(item, index) in memberluckydrawboxhistorytopget" :key="index"
            style="display: flex; border-bottom: 1px solid #ededed">
            <div class="d1">
              <img :src="`../../static/images/yt999/box/${item.LuckyNumber}.png`" alt="" width="50px" />
              <span class="boxnum">{{ item.LuckyNumber }}</span>
            </div>
            <div class="d3" style="font-size: 13px;">{{ item.UserName }}</div>
            <div class="d2" v-if="item.LuckyNumber == 0">
              <span v-if="item.IsPlayForFun" class="fun"></span>
              --
            </div>
            <div class="d2" v-else>
              <span v-if="item.IsPlayForFun" class="fun"></span>
              <span class="symbol">{{ currencySymbol }}</span>
              {{ item.WinAmount | formatCurrency2 }}
            </div>
          </li>
        </ul>
        <div style="background: #68beff; font-weight: normal">
          <span class="fun2"></span>
          {{ $t("luckbox_014") }}
        </div>
      </van-tab>
      <van-tab :title="$t('luckbox_006')" style="font-weight: bold">
        <ul style="padding: 0 10px">
          <li class="title">
            <div style="width: 33%">{{ $t("luckbox_007") }}</div>
            <div style="width: 70%;text-align: left;">{{ $t("luckbox_008") }}</div>
            <!-- <div style="width: 30%">{{ $t("luckbox_009") }}</div> -->
          </li>
          <li v-for="(item, index) in merchantluckydrawboxbymemberlist" :key="index"
            style="display: flex; border-bottom: 1px solid #ededed">
            <div class="d1" style="width: 33%">
              <img :src="`../../static/images/yt999/box/${item.LuckyNumber}.png`" alt="" width="50px" />
              <span class="boxnum">{{ item.LuckyNumber }}</span>
            </div>
            <div class="d2" style="text-align: left;">
              <span class="symbol">{{ currencySymbol }}</span>
              {{ item.WinAmount | formatCurrency2 }}
            </div>
            <!-- <div class="d3">{{ item.DisplayWinRateText }}</div>  -->
          </li>
        </ul>
      </van-tab>
    </van-tabs>


    <div class="dialog opendialog" >
      <div style="padding: 0 8px;">
        <div >{{ $t("luckbox_023") }}</div>
        <div class="openbox">
          <div @click="openactive(0,$event)">
            <img class="openimg" :src="`../../static/images/yt999/box/kk.png`" alt="" srcset="">
            <!-- <span class="opentext">?</span> -->
            <img v-show="opactive0!==2" class="hand" src="../../static/images/yt999/box/click.gif" alt="" srcset="">
            <img class="zhong" v-show="zhong ==0" src="../../static/images/yt999/box/love.gif" alt="" srcset="">
          </div>
          <div @click="openactive(1,$event)">
            <img class="openimg" :src="`../../static/images/yt999/box/kk.png`" alt="" srcset="">
            <!-- <span class="opentext">?</span> -->
            <img v-show="opactive1!==2" class="hand" src="../../static/images/yt999/box/click.gif" alt="" srcset="">
            <img class="zhong" v-show="zhong ==1" src="../../static/images/yt999/box/love.gif" alt="" srcset="">
          </div>
          <div @click="openactive(2,$event)">
            <img class="openimg" :src="`../../static/images/yt999/box/kk.png`" alt="" srcset="">
            <!-- <span class="opentext">?</span> -->
            <img v-show="opactive2!==2" class="hand" src="../../static/images/yt999/box/click.gif" alt="" srcset="">
            <img class="zhong" v-show="zhong ==2" src="../../static/images/yt999/box/love.gif" alt="" srcset="">
          </div>
        </div>
        <div v-show="dialog==0">
          <div >{{ $t("luckbox_024") }}</div>
          <div ></div>
        </div>
        <div  v-show="dialog==1">
          <div >{{ $t("luckbox_025") }}</div>
          <div style="color: red;font-size: 16px"><span class="symbol">{{ currencySymbol }}</span>{{ winAmount | formatCurrency2 }}</div>
          <div class="ok" @click="closeDialog()">{{$t('common_0001')}}</div>
        </div>
        <div  v-show="dialog==2">
          <div >{{ $t("luckbox_026") }}</div>
          <div style="text-decoration: underline;">{{$t('luckbox_004')}}</div>
          <div class="ok" @click="closeDialog()">{{$t('common_0001')}}</div>
        </div>
        <div  v-show="dialog==3">
          <div >{{ $t("luckbox_027") }}</div>
          <div style="color: red;font-size: 16px"><span class="symbol">{{ currencySymbol }}</span>{{ winAmount | formatCurrency2 }}</div>
          <div class="ok" @click="closeDialog()">{{$t('luckbox_020')}}</div>
        </div>
      </div>
    </div>

    <div class="dialog ruledialog" >
      <div class="dialog-inner">
        <div class="dialog-title" style="height: 35px;line-height: 35px;">
          <h5>{{ $t("luckbox_022") }}</h5>
        </div>
        <div class="dialog-text">
          <f7-block class="block-message">
            <div class="block-content" v-html="rule.RulesContent" style="height:350px"></div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-next border-radius-left" @click="closeRule()">{{$t("common_0001") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>

  </f7-page>
</template>

<script>
import { getCookie, getStore } from "@/config/utils";
import { luckydrawbox, getLuckyDrawChanceDetail, getMerchantluckydrawboxbymemberlist, getMemberluckydrawboxhistorytopget, getmerchantluckydrawboxrulesbymemberget } from "../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      luckyNumber: -1,
      winAmount: 0,
      isPlayForFun: true,
      remaining: 0,
      merchantluckydrawboxbymemberlist: [],
      memberluckydrawboxhistorytopget: [],
      currencySymbol: "",
      clickFlag: true,
      Ygo: false,
      Ngo: false,
      opactiveall: 0,
      opactive0: 0,
      opactive1: 0,
      opactive2: 0,
      userBoxNum: 0,
      userClickNum: 0,
      allNumbers: [],
      updateTime: null,
      mysetTimeout: null,
      dialog: 0,
      openbtn: false,
      zhong: -1,
      rule:{},
      showbox: false,
      machine: null
    };
  },
  watch: {},
  computed: {},
  methods: {
    onPageInit() {
      if ("luckybox" == this.$f7router.currentRoute.name) {
        this.updateTime = setInterval(() => {
          this.nagetMemberluckydrawboxhistorytopget();
        }, 5000);
      }
      
    },
    init() {
      const self = this;
      self.machine = $("#ballMachine").lotteryMachine({
        containerRadius: 150,
        waitInterval: 1000,
        playSound: true,
        callback: function (data) {
          // alert(data); //On finish running lottery numbers, do something 回调
          self.opactive0 = 1;
          self.opactive1 = 1;
          self.opactive2 = 1;
          self.openbtn = true;
          self.showbox = true;
        },
      });
    },
    openactive(id, event) {
      // if(this.clickFlag){
      //   return;  //  防止没有抽球前误点
      // }
      const self = this;
      self.userBoxNum = id;
      self.userClickNum++;

      const params = { IsPlayForFun: self.isPlayForFun, MemberChooseIndex: id, TotalChooseCount: 3 }; //IsPlayForFun: true=试玩 / false=正式玩
      const d = { data: JSON.stringify(params) };
      if (self.luckyNumber == -1) {
        luckydrawbox(d).then((data) => {
          if (data.Code === "NoError") {
            if (id == 0) {
              self.opactive0 = 2;
            } else if (id == 1) {
              self.opactive1 = 2;
            } else if (id == 2) {
              self.opactive2 = 2;
            }
            self.luckyNumber = data.Data.LuckyNumber;
            self.winAmount = data.Data.WinAmount;
            self.allNumbers = data.Data.AllNumbers;
            self.nagetLuckyDrawChanceDetail();


            //显示球数字
            const currentElement = event.target.parentElement; // 获取当前点击的DOM元素
            const imgElement = currentElement.querySelector('img');
            const spanElement = currentElement.querySelector('span');
            if (imgElement && self.allNumbers.length > 0) {
              imgElement.src = `../../static/images/yt999/box/k${self.allNumbers[id]}.png`;
            }

            const openElement = document.querySelector('.open');
            // 获取 'open' 元素下面的第二个 <div>
            const secondDiv = openElement.querySelectorAll('div')[id];
            // 获取第二个 <div> 下的 <img> 元素
            const imgElement2 = secondDiv.querySelector('img');
            imgElement2.src = `../../static/images/yt999/box/k${self.allNumbers[id]}.png`;


            self.mysetTimeout = setTimeout(() => {
              self.zhong = id;
              if (self.luckyNumber==0) {
                self.dialog = 2 ;
              }else{
                if (self.isPlayForFun) {
                  self.dialog = 3 ;
                }else{
                  self.dialog = 1 ;
                }
              }
            }, 1000);
            self.clickFlag = true;
          
          } else {
            // $("#ballMachine").lotteryMachine("top");  // 停止球转动
            let loginLoading = self.$f7.dialog.create({
              title: "",
              text: data.Data,
              cssClass: "dialog-preloadgame",
            }).open();
            setTimeout(() => {
              loginLoading.close();
            }, 2000);
          }
          self.openbtn = false;
        });
      } else {
        //显示球数字
        const currentElement = event.target.parentElement; // 获取当前点击的DOM元素
        const imgElement = currentElement.querySelector('img');
        const spanElement = currentElement.querySelector('span');
        if (imgElement && self.allNumbers.length > 0) {
          imgElement.src = `../../static/images/yt999/box/k${self.allNumbers[id]}.png`;
          // spanElement.innerHTML = self.allNumbers[id];
          const openElement = document.querySelector('.open');
          // 获取 'open' 元素下面的第二个 <div>
          const secondDiv = openElement.querySelectorAll('div')[id];
          // 获取第二个 <div> 下的 <img> 元素
          const imgElement2 = secondDiv.querySelector('img');
          imgElement2.src = `../../static/images/yt999/box/k${self.allNumbers[id]}.png`;
        }
        
      }
      if (id == 0) {
        self.opactive0 = 2;
      } else if (id == 1) {
        self.opactive1 = 2;
      } else if (id == 2) {
        self.opactive2 = 2;
      }
      

    },

    open() {
      const self = this;
      var tip = "";
      // 未中奖
      if (self.luckyNumber == 0) {
        tip = `<div style="text-align: center;"><h4 style="text-align: center;margin:3px 0;">${self.$t("luckbox_017")}</h4><div style="width: 70px;height: 70px;background-image: url('../../static/images/yt999/box/0.png');background-size: 100% 100%;margin: 0 auto; color: white;font-size: 30px;font-weight: bold;line-height: 70px;">0</div><span style="text-align: center;margin:5px 0;text-decoration: underline;font-size:14px;">${self.$t("luckbox_004")}</span></div>`;
      } else {
        let jin = self.$options.filters.formatCurrency2(self.winAmount);
        var text = "";
        if (self.isPlayForFun) {
          text = self.$t("luckbox_017");
        } else {
          text = self.$t("luckbox_018");
        }
        tip = `<div style="text-align: center;"><h4 style="text-align: center;margin:3px 0;">${text}</h4><div style="width: 70px;height: 70px;background-image: url('../../static/images/yt999/box/${self.luckyNumber}.png');background-size: 100% 100%;margin: 0 auto; color: white;font-size: 30px;font-weight: bold;line-height: 70px;">${self.luckyNumber}</div><h4 style="text-align: center;margin:5px 0;">${self.$t("luckbox_016")}&nbsp;<span style="color: red;"><span class="symbol">${self.currencySymbol
          }</span>${jin}</span></h4></div>`;
      }
      self.logout(tip);

    },
    
    //
    logout(tip) {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      var btntext = "";
      if (self.luckyNumber == 0) {
        btntext = self.$t("luckbox_019");
      } else {
        // if (self.isPlayForFun) {
        //   btntext = self.$t("luckbox_020");
        // } else {
        //   btntext = this.$t("common_0001");
        // }
        btntext = this.$t("common_0001");
      }
      self.$f7.dialog
        .create({
          title: this.$t(""),
          text: tip,
          cssClass: "dialog-logout",
          buttons: [
            {
              text: btntext,
              cssClass: "dialog-ok",
              onClick: () => {
                self.clickFlag = true;
                // $("#ballMachine").lotteryMachine("clear");
                // if (self.luckyNumber == 0) {
                //   self.getluckydrawbox(self.isPlayForFun);
                // } else {
                //   return;
                // }
              },
            },
          ],
        })
        .open();
    },
    kaiqiu(){
      // this.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      this.$f7.dialog.open(".opendialog", true);
    },
    closeDialog(){
      this.$f7.dialog.close(".opendialog");
    },
    openRule(){
      this.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      this.$f7.dialog.open(".ruledialog", true);
    },
    closeRule(){
      this.$f7.dialog.close(".ruledialog");
    },
    getluckydrawbox(param) {
      this.isPlayForFun = param;
      if (param) {
        if (!this.Ngo) {
          this.$f7.toast.create({
            text: this.$t("luckbox_028"),
            position: "center",
            closeTimeout: 3000,
            cssClass: "toast-login",
            on: {
              closed: function () {
              },
            },
          })
          .open();
          // this.$f7.dialog.alert("可用次数不足", this.$t('common_0004'));
          return;
        } 
      }else{
        if (!this.Ygo) {
        this.$f7.toast.create({
          text: this.$t("luckbox_028"),
          position: "center",
          closeTimeout: 3000,
          cssClass: "toast-login",
          on: {
            closed: function () {
            },
          },
        })
        .open();
        // this.$f7.dialog.alert("可用次数不足", this.$t('common_0004'));
        return;
        } 
      }


      if (!this.clickFlag) {
        // this.$f7.dialog.alert("请勿重复点击", this.$t('common_0004'));
        return;
      } else {
        this.clickFlag = false;
      }

      //初始化数据
      this.opactive = 0;
      this.opactive0 = 0;
      this.opactive1 = 0;
      this.opactive2 = 0;
      this.userClickNum = 0;
      this.luckyNumber = -1;
      this.dialog = 0 ;
      this.zhong = -1;
      this.showbox = false;
      // 获取所有类名为 'opentext' 的元素
      // const textelements = document.querySelectorAll('.opentext');
      const imgelements = document.querySelectorAll('.openimg');
      const showboxlements = document.querySelectorAll('.showbox'); 
      // 遍历并修改每个元素的HTML内容
      // textelements.forEach(element => {
      //   element.innerHTML = '?';
      // });
      imgelements.forEach(element => {
        element.src = `../../static/images/yt999/box/kk.png`;
      });
      showboxlements.forEach(element => {
        element.src = `../../static/images/yt999/box/kk.png`;
      });
      $("#ballMachine").lotteryMachine("run", '111');
    },
    nagetLuckyDrawChanceDetail() {
      getLuckyDrawChanceDetail().then((data) => {
        if (data.Code === "NoError") {
          this.remaining = data.Data.LuckyDrawBox_PlayForRealTotal - data.Data.LuckyDrawBox_PlayForRealCurrent;
          if ((data.Data.LuckyDrawBox_PlayForRealTotal - data.Data.LuckyDrawBox_PlayForRealCurrent) > 0) {
            this.Ygo = true;
          }
          if ((data.Data.LuckyDrawBox_PlayForFunTotal - data.Data.LuckyDrawBox_PlayForFunCurrent) > 0) {
            this.Ngo = true;
          }
        }
      });
    },
    nagetMerchantluckydrawboxbymemberlist() {
      getMerchantluckydrawboxbymemberlist().then((data) => {
        if (data.Code === "NoError") {
          this.merchantluckydrawboxbymemberlist = data.Data;
        }
      });
    },
    nagetMemberluckydrawboxhistorytopget() {
      getMemberluckydrawboxhistorytopget().then((data) => {
        if (data.Code === "NoError") {
          this.memberluckydrawboxhistorytopget = data.Data;
        }
      });
    },
    nagetmerchantluckydrawboxrulesbymemberget() {
      getmerchantluckydrawboxrulesbymemberget().then((data) => {
        if (data.Code === "NoError") {
          this.rule = data.Data;
        }
      });
    },
    onTabChange() {
      // 阻止默认的页面滚动行为
      window.scrollTo(0, 0); // 保持页面不滚动
    },

  },
  created() {
    
    this.currencySymbol = getStore("currencySymbol");
  },
  mounted() {
    const self = this;
    this.nagetLuckyDrawChanceDetail();
    this.nagetMerchantluckydrawboxbymemberlist();
    this.nagetMemberluckydrawboxhistorytopget();
    this.nagetmerchantluckydrawboxrulesbymemberget();
    this.init();
  },
  destroyed() {
    clearInterval(this.updateTime);
    clearTimeout(this.mysetTimeout);
    $("#ballMachine").lotteryMachine("clearTime");
    window.location.reload();
  }
  
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner{    //头部颜色更换
  color: white;
  background: linear-gradient(to right, #497cfc, #3d7cf9);
  a{
    color: white;
  }
}
.content {
  padding: 10px;
  background:#68beff;
  background-image: url("../../static/images/yt999/box/luckboxbg.png");
  background-size: 100% 74%;
  background-repeat: no-repeat;
  padding-top: 50px;
  position: relative;
  .rule{
    color: white;
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 200;
    background: rgba(0, 0, 0, 0.2);
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 25px;
    padding: 0 12px;
  }
  // height: 100%;
  .box {
    width: 100%;
    height: 330px;
    margin-top: 290px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 90px;

    div {
      width: 90px;
      height: 90px;
      background: url("../../static/images/yt999/item.png");
      background-size: 100% 100%;
      margin: 10px;
      box-sizing: border-box;
    }

    .active {
      border: 1px solid white;
      border-radius: 10px;
    }
  }

  .dan {
    // background: white;
    text-align: center;
    position: relative;

    .bigbox {
      position: relative;
      z-index: 10;
      width: 350px;
      margin: 0 auto;
      // background: url('../../static/images/yt999/box/quan.png');
    }

    .yu {
      // width: 186px;
      // margin: 0 auto;
      // height: 40px;
      // line-height: 41px;
      // background: #deb035;
      // font-size: 17px;
      // color: white;
      // border-radius: 10px;
      // margin-bottom: 20px;
      font-size: 17px;
      position: absolute;
      top: 377px;
      width: 100%;
    }

    .fen {
      width: 85%;
      margin: auto;
      //margin: 20px 0;
      font-size: 16px;
      text-decoration: underline;
    }

    .mian {
      width: 300px;
      height: 40px;
      line-height: 40px;
      background: white;
      margin: 20px auto;
      border-radius: 10px;
      font-size: 14px;
    }

    .yin {
      width: 100%;
      height: 165px;
      position: absolute;
      top: 217px;
      left: 0;
    }

    .quan {
      width: 422px;
      position: absolute;
      top: -79px;
      left: -53px;
    }
  }

  .open {
    width: 210px;
    height: 82px;
    margin: 0 auto;
    position: relative;
    bottom: 83px;
    z-index: 20;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    left: -3px;
    div {
      position: relative;
      overflow: hidden;
    }

    img {
      width: 76px;
      // height: 90px;
    }
    .zhong{
      width: 20px;
      position: absolute;
      left: 0;
      top: 0;

    }
    .opentext {
      position: absolute;
      line-height: 65px;
      font-size: 26px;
      left: 26px;
      top: 0;
    }
  }

  .opactive {
    background-image: url(../../static/images/yt999/box/click.gif);
    background-size: 86% 99%;
    border-radius: 10px;
    background-position-x: 10px;
    background-position-y: 7px;
    background-repeat: no-repeat;
  }

  .go {
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    width: 90%;
    border: none;
    border-radius: 10px !important;
    margin: 0 auto;
    text-transform: none;
    background: var(--f7-THEME-color);
    color: white;
    position: relative;
    z-index: 100;
  }

  .nogo {
    background: #d6d6d6;
    height: 46px;
    line-height: 46px;
    font-size: 16px;
    width: 90%;
    border: none;
    border-radius: 10px !important;
    margin: 0 auto;
    text-transform: none;
    color: white;
  }
}

.van {
  padding: 10px;
  background: #68beff;

  ul li {
    height: 52px;
    text-align: center;
  }

  .title {
    display: flex;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(237, 237, 237);
    font-weight: bold;
  }

  .d1 {
    width: 33%;
    position: relative;

    .boxnum {
      position: absolute;
      top: 14px;
      font-weight: bold;
      font-size: 17px;
      width: 100%;
      text-align: center;
      left: 0;
    }
  }

  .d2 {
    width: 33%;
    line-height: 55px;
    font-size: 13px;
    font-weight: bold;
    position: relative;

    // text-align: left;
    .fun {
      width: 10px;
      height: 10px;
      display: inline-block;
      background: #ccc;
      border-radius: 10px;
      position: absolute;
      left: -5px;
      top: 23px;
    }
  }

  .d3 {
    width: 33%;
    line-height: 55px;
    font-size: 16px;
  }

  ::v-deep.van-tabs--line .van-tabs__wrap {
    border-radius: 10px 10px 0 0;
  }

  ::v-deep .van-tab__text--ellipsis {
    font-weight: bold;
  }

  .fun2 {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #ccc;
    border-radius: 10px;
    margin: 10px 10px 0 10px;
  }

  ::v-deep .van-tab--active {
    color: #323233;
    font-weight: 500;
    background: linear-gradient(to bottom, var(--f7-THEME-color), #fff);
    border-radius: 10px 10px 0 0;
  }

  ::v-deep .van-tabs__line {
    display: none;
  }

  ::v-deep .van-tabs__nav {
    background-color: #f0f0f0;
  }
}

.opendialog{
  background: white;
  font-weight: bold;
  padding: 15px 0;
  min-height: 260px;
  div{
    margin: 5px 0;
  }
  .openbox{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* 每个子元素占据1/3的宽度 */
    grid-gap: 10px;
    width: 250px;
    margin: 0 auto;
    div{
      position: relative;
      img{
        width: 100%;
        // width: 60px;
      }
      .opentext{
        position: absolute;
        display: block;
        text-align: center;
        top: 18px;
        left: 29px;
        font-size: 30px;
      }
      .hand{
        position: absolute;
        left: 13px;
        top: 30px;
        width: 80%;
      }
    }
    .zhong{
      width: 25px;
      position: absolute;
      left: 0;
      top: 0;
    }
    
  }
  .ok{
    width: 200px;
    background: var(--f7-THEME-color);
    height: 35px;
    line-height: 35px;
    color: white;
    border-radius: 6px;
    margin: 10px auto;
    margin-top: 20px;
  }
}
::v-deep .van-tabs__content {
  background: white;
 
}

::v-deep .page-content::after {
  height: 0;
}

.ruledialog {
  width: 86%;
  margin: 0 7%;
  background-color: #f3f3f3 !important;
  //border-radius: 8px;
  border: 1px solid var(--f7-dialog-border-color);
  left: 0;

  .dialog-inner {
    padding: 0;
    text-align: left;

    .dialog-title {
      text-align: center;
      position: relative;
      font-size: 18px;
      padding-bottom: 4px;
      h3 {
        margin: 0 0 2px 0;
      }

      h5 {
        margin: 0;
        font-weight: normal;
      }
    }

    .dialog-title:after {
      content: "";
      position: absolute;
      z-index: 1;
      background: url(../../static/images/yt999/light.png) no-repeat;
      background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
      height: var(--f7-height-size-2);
      width: 100%;
      display: block;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      transform-origin: 50% 100%;
      transform: scaleY(1);
      transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .dialog-text {
      .block-message {
        margin: var(--f7-margin-size-0);
        padding: var(--f7-padding-size-0);
        width: var(--f7-width-size-pt100);

        .block-content {
          padding: var(--f7-padding-size-8) var(--f7-padding-size-10);
          height: 300px;
          overflow: hidden scroll;
          color: #464646;
          font-size: 15px;
          border-bottom: 1px solid #eae6e6;

        }

        .row-toggle {
          margin: 10px 10px 0;

          span {
            color: var(--f7-dialog-button-text-color-right);
          }

          .toggle {
            margin-top: var(--f7-margin-size-f2);
          }
        }

        .block-footer {
          margin-top: 8px;

          .row.no-gap {
            --f7-grid-gap: 0px;
          }

          .button {
            height: 50px;
            line-height: 50px;
            color: var(--f7-color-white);
            font-size: var(--f7-px-16);
          }

          .btn-next {
            background: var(--f7-dialog-button-bg-color-right);
            color: var(--f7-dialog-button-text-color-right);
          }

          .btn-next.button-disabled {
            opacity: 0.6;
            // color: var(--f7-dialog-button-text-disable-color-right) !important;
          }

          .btn-close {
            background-color: var(--f7-dialog-button-bg-color-left);
            color: var(--f7-dialog-button-text-color-left);
            border-radius: 0;
          }
        }
      }
    }
  }
}

</style>
