<template>
  <f7-page no-toolbar no-swipeback name="financial" class="page-center" @page:init="onPageInit()">
    <f7-navbar innerClass="navbar-inner">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("home_0094") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="content">
      <div class="txt1">{{ $t("award_0012") }}</div>
      <div class="txt2">{{ $t("award_0013") }}</div>
      <div class="title" style="position: relative;">
        <span style="position: relative;z-index: 11;">{{ $t("award_0002") }}</span>
        <img src="../../static/images/yt999/awardImg.png"  alt="" srcset="" class="liwu">
      </div>
      <div class="box" v-show="DirectMemberVipBonusDetails.length>0">
        <div class="box-header">
          {{ $t("award_0003") }}
        </div>
        <div class="box-body boxs" v-for="(item,index) in DirectMemberVipBonusDetails" :key="index">
          <div class="icon">
            <span>{{ item.SubBonus | formatCurrency2}}</span>
          </div>
          <div class="info">
            <p>{{ $t("award_0004") }}</p>
            <p>{{ item.SubCurrActiveCount }}/{{ item.SubActiveCount }}</p>
            <p v-if="item.SubCurrActiveCount>item.SubActiveCount"><f7-progressbar :progress="(item.SubActiveCount/item.SubActiveCount)*100" style="width:90%;height:5px; border-radius: 5px;background: #e3e3e3;" color="#2366cc" /></p>
            <p v-else><f7-progressbar :progress="(item.SubCurrActiveCount/item.SubActiveCount)*100" style="width:90%;height:5px; border-radius: 5px;background: #e3e3e3;" color="#2366cc" /></p>
          </div>
          <div>
            <button class="button" v-if="item.SubStatus == 1">{{ $t("award_0005") }}</button>
            <button class="button" v-else-if="item.SubStatus == 2" style="background: green;" @click="reveiceAward(item.SubSettingID,item.SubBonus)">{{ $t("award_0006") }}</button>
            <button class="button" v-else-if="item.SubStatus == 3" style="background: #ccc;">{{ $t("award_0007") }}</button>
          </div>
        </div>
      </div>
      <div class="un" v-show="TeamMemberVipBonusDetails.length>0">
        <div class="title">
          <span>{{ $t("award_0008") }}</span>
          <img src="" alt="" srcset="">
        </div>
        <div class="box">
          <div class="box-header">
            {{ $t("award_0009") }}
          </div>
          <div class="box-body boxs" v-for="(item,index) in TeamMemberVipBonusDetails" :key="index">
            <div class="icon">
              <span>{{ item.TeamBonus | formatCurrency2}}</span>
            </div>
            <div class="info">
              <p>{{ $t("award_0010") }}</p>
              <p>{{ item.TeamCurrActiveCount }}/{{ item.TeamActiveCount }}</p>
              <p v-if="item.TeamCurrActiveCount>item.TeamActiveCount"><f7-progressbar :progress="(item.TeamActiveCount/item.TeamActiveCount)*100" style="width:90%;height:5px; border-radius: 5px;background: #e3e3e3;" color="#2366cc" /></p>
              <p v-else><f7-progressbar :progress="(item.TeamCurrActiveCount/item.TeamActiveCount)*100" style="width:90%;height:5px; border-radius: 5px;background: #e3e3e3;" color="#2366cc" /></p>
            </div>
            <div>
              <button class="button" v-if="item.TeamStatus == 1">{{ $t("award_0005") }}</button>
              <button class="button" v-else-if="item.TeamStatus == 2" style="background: green;" @click="reveiceAward(item.SubSettingID,item.TeamBonus)">{{ $t("award_0006") }}</button>
              <button class="button" v-else-if="item.TeamStatus == 3" style="background: #ccc;">{{ $t("award_0007") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getCookie } from "@/config/utils";
import { getAwardList,getAward } from "../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      TeamMemberVipBonusDetails: [],
      DirectMemberVipBonusDetails: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    onPageInit(){
      this.getsAwardList();
    },
    getsAwardList(){
      getAwardList().then((data) => {
        if (data.Code === "NoError") {
          this.TeamMemberVipBonusDetails = data.Data.TeamMemberVipBonusDetails;
          this.DirectMemberVipBonusDetails = data.Data.DirectMemberVipBonusDetails;
        }
      });
    },

    reveiceAward(id,money) {
      const params = { "SettingID": id };
      const d = { data: JSON.stringify(params) };
      let tip = '';
      let jin = this.$options.filters.formatCurrency2(money);
      getAward(d).then((data) =>{
        if (data.Code === "NoError") {
          tip ='<div style="text-align: center;"><img src="../../static/images/yt999/awardImg.png" height="100px"></div><h4 style="text-align: center;margin:3px 0;">'+this.$t("award_0011")+'</h4><h4 style="color: #2366cc;text-align: center;margin: 0;">R$ '+jin+'</h4>';
          this.getsAwardList();
        }else{
          tip =data.Data;
        }
        this.logout(tip);
      });
      
    },
    logout(tip) {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog.create({
        title: this.$t(""),
        text: tip,
        cssClass: "dialog-logout",
        buttons: [
          {
            text: this.$t("common_0001"),
            cssClass: "dialog-ok",
            onClick: () => {
             
            },
          },
        ],
      }).open();
    },
  },
  created() {

  },
  mounted() {

  },
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner{    //头部颜色更换
  color: white;
  background: linear-gradient(to right, #2366cc, #2366cc);
  a{
    color: white;
  }
}
.content {
  padding: 10px;
  background: linear-gradient(to bottom, var(--f7-THEME-color) 5%, #66b3ff 16%); 
  min-height: 98%;
  .txt1{
    padding-left: 6px;
    color: white;
    font-size: 28px;
    font-weight: bold;
  }
  .txt2{
    width: 58%;
    padding-left: 6px;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  .title{
    position: relative;
    font-size: 16;
    font-weight: bold;
    margin: 10px 0px;
    padding-left: 10px;
    .liwu{
      height: 136px;
      position: absolute;
      right: 30px;
      bottom: -21px;
      z-index: 10;
    }
  }
  .title::after{
    content: '';
    position: absolute;
    top: 4px;
    left: 0px;
    transform: translateX(-50%);
    width: 3px;
    height: 60%;
    background-color: var(--f7-THEME-color);
  }

  .box{
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    .box-header{
      border-bottom: 2px solid #e7e7e7;
      padding-bottom: 10px;
    }
    .box-body{
      height: 50px;
      padding-top: 10px;
      display: flex;
      .icon{
        span{
          display: inline-block;
          height: 50px;
          line-height: 38px;
          text-align: center;
          color: red;
          font-weight: bold;
          background: url(../../static/images/yt999/awardRed.png);
          background-size: 100% 100%;
          width: 65px;
          // padding: 0px 4px;
          font-size: 12px;
        }
      }
      .info{
        p{
          margin: 0px;
          font-size: 12px;
        }
        p:nth-child(1){
          margin-top: 4px;
          color: #a7a7a7;
          font-size: 12px;
        }
      }
      .button{
        line-height: 30px;
        height: 30px;
        border-radius: 15px;
        margin: 0 auto;
        margin-top: 11px;
        width: 80px;
        text-transform: none;
        white-space: normal;
      }
      div:nth-child(1){
        width: 20%;
        margin-right: 5px;
        text-align: center;
      }
      div:nth-child(2){
        width: 53%;
      }
      div:nth-child(3){
        width: 27%;
      }
    }
    .boxs{
      padding-bottom: 10px;
      border-bottom: 2px solid #e7e7e7;
    }
    .boxs:last-child{
      border: none;
    }
  }
}
::v-deep .page-content::after{
  height: 0;
}
</style>