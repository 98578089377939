<template>
  <f7-page no-toolbar no-swipeback login-screen @page:init="onPageInit" class="page-login" style="margin-top: 20px">
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="back">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title style="">{{ $t("login_0001") }}</f7-nav-title>
      <f7-nav-right class="nav-balance">
        <f7-link @click="openChangeLanguagePopup">
          <img class="btn-dowload" src="../../static/images/yt999/lang.png" height="24" style="margin-right: 10px" />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-login-screen-title>
      <img :src="`../../static/images/yt999/merchant/${$f7.params.fileName}/logo/dlog.png`" />
    </f7-login-screen-title>
    <form class="list no-hairlines-md">
      <ul>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media">
            <i class="f7-icons">person_circle</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" :placeholder="$t('login_0002')" v-model="username" :data-error-message="$t('login_0003')" />
              <span class="input-clear-button"></span>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info">
          <div class="item-media">
            <i class="f7-icons">lock_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input id="login-pwd" :type="!togglePwdType ? 'password' : 'text'" v-model="password" :placeholder="$t('login_0004')" :data-error-message="$t('login_0005')" />
              <i class="f7-icons input-eyes" @click.self="togglePassword" v-if="togglePwdType">eye</i>
              <i class="f7-icons input-eyes" @click.self="togglePassword" v-else>eye_slash</i>
            </div>
          </div>
        </li>
        <li class="item-content item-input item-input-with-info" v-if="baseInfo.CheckVerifyCodeOnMemberLogin">
          <div class="item-media">
            <i class="f7-icons">exclamationmark_shield</i>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" name="user-verify" v-model="verifyCode" :placeholder="$t('login_0006')" required />
              <span class="span-verify">
                <img @click.self="updateVerify" :src="`${verifyUrl}`" alt />
              </span>
            </div>
          </div>
        </li>
      </ul>
      <f7-block class="panel-forgetpwd">
        <f7-row no-gap>
          <f7-col>
            <!-- <f7-link @click="forgetPwd" class="span-forgetpwd color-lightblue text-14">{{ $t("login_0007") }}</f7-link> -->
            <f7-link @click="ontip()" class="span-forgetpwd color-lightblue text-14">{{ $t("login_0007") }}</f7-link>
          </f7-col>
          <f7-col class="text-right">
            <span class="color-graytint text-14">{{ $t("login_0008") }}</span>
            <f7-toggle color="blue" @change="rememberPwd" :checked="toggleChecked"></f7-toggle>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block strong>
        <f7-button fill raised large @click="signIn" class="login-button">{{ $t("login_0009") }}</f7-button>
        <f7-block-footer>
          <f7-col style="font-size: 15px;">{{ $t("login_0010") }}</f7-col>
          <f7-link href="/register/" class="color-lightblue" style="font-size: 17px;font-weight: bold;">{{ $t("login_0011") }}</f7-link>
        </f7-block-footer>
      </f7-block>
    </form>

    <!-- 在线客服popup -->
    <f7-popup v-show="showOnlinePopup" class="onlineCustom-popup" :opened="onlinePopupOpened" @popup:open="openOnlinePopup" @popup:close="closeOnlinePopup">
      <f7-page>
        <f7-navbar :title="$t('login_0012')">
          <f7-nav-right>
            <f7-link popup-close><i class="f7-icons">multiply_circle</i></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <iframe :src="onlineCustomUrl" class="iframe-onlineCustom" frameborder="0"></iframe>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import api from "../../config/api";
import { getStore, removeStore, setStore, setCookie, getCookie } from "../../config/utils";
import { getInfo, login, checkUserLogin, mobileVerifyCode } from "../../axios/api";
import { mapGetters, mapMutations } from "vuex";
import common from "../../service/common";

export default {
  data() {
    return {
      username: "",
      password: "",
      verifyCode: "",
      verifyUrl: "",
      toggleChecked: false,
      togglePwdType: false,
      checkInterval: null,
      onlinePopupOpened: false,
      onlineCustomUrl: "",
      getInfoStatus: true,
      showVerifyCode: true,
      baseInfo: {},
      showOnlinePopup: true,
    };
  },
  computed: {
    ...mapGetters(["getMerchantInfo", "getLoginState", "onlineCustomLoaded"]),
  },
  watch: {
    getMerchantInfo: function (val) {
      this.baseInfo = val.BaseInfo;
    },
  },
  methods: {
    ...mapMutations(["SETLOGINSTATE", "SETLOGININFO"]),
    onPageInit() {},
    back() {
      this.$f7router.navigate("/");
    },
    openChangeLanguagePopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-language", true);
    },
    signIn() {
      const self = this;
      const app = self.$f7;
      const router = self.$f7router;
      if (self.baseInfo.CheckVerifyCodeOnMemberLogin && !self.verifyCode) {
        app.dialog.alert(this.$t("login_0013"), this.$t("common_0004"));
        return;
      }
      if (!self.username) {
        app.dialog.alert(this.$t("login_0014"), this.$t("common_0004"));
        return;
      }
      if (!self.password) {
        app.dialog.alert(this.$t("login_0015"), this.$t("common_0004"));
        return;
      }
      const params = {
        UserName: self.username,
        Password: self.password,
        VerifyCode: self.verifyCode,
        Device: getStore("device") ? getStore("device") : "Mobile",
        AppVer: getStore("appVer") ? getStore("appVer") : "",
        ClientID: getStore("cid") ? getStore("cid") : "",
      };
      let d = { data: JSON.stringify(params) };
      self.$f7.params.dialog.closeByBackdropClick = false;
      //self.$f7.$('.dialog-backdrop').css('background-color', 'transparent');
      let loginLoading = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("login_0016"),
          cssClass: "dialog-preloadgame",
        })
        .open();

      login(d).then((data) => {
        loginLoading.close();

        if (data.Code === "NoError" && data.Data) {
          removeStore("userInfo");
          self.SETLOGINSTATE(true); //设置登录状态为true
          self.SETLOGININFO(data.Data); //存储Vuex store userinfo
          setCookie("zz", data.Data.SessionId, 7);
          setStore("zz", data.Data.SessionId);
          setCookie("LoginName", data.Data.LoginName, 7);
          setCookie("blance", data.Data.Balance, 7);
          setCookie("test", data.Data.IsTest, 7);
          setCookie("p", data.Data.PromoCode, 7);
          setCookie("EnableSendRedPacket", data.Data.EnableSendRedPacket, 7);
          self.setUserStore(self.username, self.password, data.Data); //存储localStore userinfo
          //self.loginCheck();

          if (!self.toastLoginStatus) {
            self.toastLoginStatus = self.$f7.toast
              .create({
                text: this.$t("login_0017"),
                position: "center",
                closeTimeout: 1000,
                cssClass: "toast-login",
                on: {
                  closed: function () {
                    self.$f7router.navigate("/home/");
                  },
                },
              })
              .open();
          }
        } else {
          self.$f7.params.dialog.closeByBackdropClick = true;
          self.$f7.$('.dialog-backdrop').css('background-color', 'rgba(0, 0, 0, 0.74)');
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"),function () {
            // self.$f7.$(".dialog-backdrop").css("background", "transparent");
          });
          self.updateVerify();
        }
      });
    },
    ontip() {
      this.$f7.dialog.alert(this.$t("login_0018"), this.$t("common_0004"),function () {
        // this.$f7.$(".dialog-backdrop").css("background", "transparent");
      });
    },
    updateVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
    focusVerify() {
      const uid = common.uuid();
      setCookie("u", uid, 7);
      this.verifyUrl = `${api.verifyimage}?u=${uid}`;
    },
    setUserStore(u, p, d) {
      const self = this;
      if (self.toggleChecked) {
        setStore("lu", u);
        setStore("lp", p);
      }
      const user = {
        la: d.LastLoginAddress,
        li: d.LastLoginIP,
        lt: d.LastLoginTs,
      };

      // setStore('ui', JSON.stringify(user));
      // setStore('lv', d.VipLevelName);
    },
    rememberPwd(event) {
      const self = this;
      if (!event.target.checked) {
        removeStore("lu");
        removeStore("lp");
        self.username = "";
        self.password = "";
        self.toggleChecked = false;
      } else {
        self.toggleChecked = true;
      }
    },
    togglePassword(event) {
      const self = this;
      const $$ = self.$f7.$;
      if (!self.togglePwdType) {
        self.togglePwdType = true;
        $$("#login-pwd").attr("type", "text");
      } else {
        self.togglePwdType = false;
        $$("#login-pwd").attr("type", "password");
      }
    },
    checkUserLogin() {
      const self = this;
      if (self.getLoginState) {
        checkUserLogin().then((data) => {
          if (!data.Data || data.Code === "SessionNotExist") {
            clearInterval(self.checkInterval);
            self.SETLOGINSTATE(false);
            common.logoutEvent();
            self.$f7router.navigate(`/`);
          }
        });
      }
    },
    loginCheck() {
      const self = this;
      clearInterval(self.checkInterval);
      self.checkUserLogin();
      self.checkInterval = setInterval(() => {
        self.checkUserLogin();
      }, 15000);
    },
    getMerchantInfoDetail() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          self.baseInfo = data.Data.BaseInfo;
          setStore("ShowTW", data.Data.BaseInfo.ShowTW);

        }
      });
    },
    forgetPwd() {
      const self = this;
      const baseInfo = self.getMerchantInfo.BaseInfo;
      if (baseInfo.CSType == 2) {
        if (!this.onlineCustomLoaded) {
          let loading = self.$f7.dialog
            .create({
              title: "",
              text: "Loading...",
              cssClass: "dialog-preloadgame",
            })
            .open();
          setTimeout(() => {
            loading.close();
            this.forgetPwd();
          }, 1500);
        } else {
          window.Tawk_API.toggle();
        }
      } else {
        self.onlineCustomUrl = baseInfo.MobileService;
        //self.onlinePopupOpened = true;
        window.open(self.onlineCustomUrl, "_blank");
      }
    },
    openOnlinePopup() {
      this.showOnlinePopup = true;
    },
    closeOnlinePopup() {
      this.onlinePopupOpened = false;
      this.showOnlinePopup = false;
    },
  },
  created() {
    const self = this;
    self.toggleChecked = Boolean(getStore("lu") && getStore("lp")) || false;
    self.username = self.toggleChecked ? getStore("lu") : "";
    self.password = self.toggleChecked ? getStore("lp") : "";
    self.getMerchantInfoDetail();
    self.updateVerify();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page-content {
  margin-top: 20px;
  .login-button {
    color: #ffffff !important;
  }
  .item-media {
    .f7-icons {
      margin-top: 7px;
    }
  }
}
.iframe-onlineCustom {
  width: 100%;
  height: calc(100% - 0px);
  min-height: calc(100% - 0px);
}
.span-verify {
  position: var(--f7-postion-absolute);
  right: var(--f7-px-10);
  top: var(--f7-px-4);
  z-index: 2;
}
</style>
