<template>
  <f7-page no-toolbar no-swipeback name="order-submission" @page:init="onPageInit" class="order-submission">
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="backWinloserp">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("Order_0015") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div class="top">
      <div class="statistics">
        <ul>
          <li class="p-ticketno">{{ orderInfo.TicketNo }}</li>
          <li class="p-ts">{{ orderInfo.BetTs | time_local }}</li>
          <li v-if="orderInfo.IsLuckyOrder == true" class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_lucky.png" />
            <img />
          </li>
          <li v-else class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_normal.png" />
            <img />
          </li>
        </ul>
      </div>
      <!-- 视频块 -->
      <div class="video" style="position: relative;">
        <!-- <video ref="myVideo" controls playsinline webkit-playsinline  v-if="orderInfo.ProviderGameURL"
        width="100%"
        @play="onPlay"
        @pause="onPause"
        :poster="orderInfo.PreviewImageUrl">
        <source :src="orderInfo.ProviderGameURL" type="video/mp4">
        </video> -->

        <!-- <video ref="myVideo" controls  style="width: 100%; height: 280px;filter: blur(15px);" ></video> -->

        <div class="video-container" >
          <!-- 背景虚化视频 -->
          <video class="blurred-background-video" ref="myVideo2" playsinline webkit-playsinline  muted></video>

          <!-- 前景清晰视频 -->
          <video class="foreground-video" ref="myVideo"  controls playsinline webkit-playsinline  @play="handlePlay"  @pause="handlePause" ></video>
          <div style="position: absolute;bottom: 14px;height: 28px;width: 100%;z-index: 100;"></div>
        </div>

        <div style="width: 100%;height: 285px;"></div>
      </div>
      <!-- <div class="cz">
        <span>
          <img :src="ev0?'../../../static/images/yt999/music/top1.png':'../../../static/images/yt999/music/top2.png'" height="20" srcset="" @click="randomNumbersAdd(0)">{{ randomNumbers[0] }}
        </span>
        <span>
          <img :src="ev1?'../../../static/images/yt999/music/bottom1.png':'../../../static/images/yt999/music/bottom2.png'" height="20" srcset="" @click="randomNumbersAdd(1)">{{ randomNumbers[1] }}
        </span>
        <span>
          <img :src="ev2?'../../../static/images/yt999/music/love1.png':'../../../static/images/yt999/music/love2.png'" height="20" srcset="" @click="randomNumbersAdd(2)">{{ randomNumbers[2] }}
        </span>
        <span>
          <img :src="ev3?'../../../static/images/yt999/music/gz1.png':'../../../static/images/yt999/music/gz2.png'" height="20" srcset="" @click="randomNumbersAdd(3)">{{ randomNumbers[3] }}
        </span>
        <span>
          <img :src="ev4?'../../../static/images/yt999/music/wx1.png':'../../../static/images/yt999/music/wx2.png'" height="20" srcset="" @click="randomNumbersAdd(4)">{{ randomNumbers[4] }}
        </span>
      </div> -->
      <div class="statistics">
        <ul>
          <li style="width: 65%">{{ $t("Order_0012") }}</li>
          <li class="li-right" style="width: 25%"><span class="symbol">{{ currencySymbol }}</span>{{ orderInfo.APIWLAmount | formatCurrency2}}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 65%">{{ $t("Order_0013") }}</li>
          <li class="li-right" style="width: 25%"><span class="symbol">{{ currencySymbol }}</span>{{ totalWinlose | formatCurrency2}}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul class="ulprogressbar" style="position: relative">
          <li style="width: 92%">{{ $t("order_submission_0001") }}</li>
          <li style="width: 92%;margin-top: 0px;">{{ $t("order_submission_0002") }}: <span style="color: red;">{{ Math.trunc(countDown/10) }}s</span></li>
          <li class="li-step" style="width: 100%;display: flex;align-items: center;margin-top: 0px;">
            <f7-progressbar :progress="(timeIng / maxTimeIng) * 100" style="height:5px; margin: 0 10px; border-radius: 5px; color: var(--f7-button-text-color)" color="green" />
          </li>
          <img :src="`../../../static/images/yt999/music/check_${ checkFlag }.png`" class="success" height="20px" >
        </ul>
      </div>
      <div>
        <div class="copy" v-if="countDown==0" >
          <span class="copybutton" @click="requestConfirmOrder()" >{{ $t("Order_0015") }}</span>
        </div>
        <div class="copy" v-else :style="{ background: isPlaying||isOk==false ? '#ccc' : 'var(--f7-button-bg-color)' }">
          <span class="copybutton"  @click="isOk?handlePlay():''">{{ $t("order_submission_0003") }}</span>
        </div>
      </div>
      <div style="width: 96%;margin: auto;">
        <div class="title">{{ $t("order_submission_0004") }}</div>
        <div class="introduction" style="margin: 0px;border: 0;">
          <div class="introductionone">
            <img :src="orderInfo.ProviderGameExtensionData" >
            <span class="nameSpan">{{ orderInfo.GameName }}</span>
            <span class="">{{ orderInfo.SubTitle }}</span>
          </div>
          <div style="padding: 10px 5px;">
            <p style="font-weight: bold;">{{ orderInfo.DescTitle }}</p>
            <p>{{ orderInfo.DescDetail }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog dialog-orderConfirm" id="dialog-orderConfirm">
      <div class="dialog-inner" @click="closeDialog('dialog-orderConfirm')">
        <img :src="`../static/images/yt999/lucky_${$f7.params.currLang}.png`" style="width: 100%" alt="" />
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapGetters} from "vuex";
import { getinitorderinfo, confirmorder, getmemberwinloserp,memberFinancialProductByIdGet } from "../../../axios/api";
import preloader from "../../template/preloader";
// import videoPlayer from "../../template/videoPlayer.vue";
import { getCookie,getStore  } from "../../../config/utils";
import { setTimeout } from "timers";
import Vue from "vue";
import Hls from 'hls.js';

export default {
  // components: { videoPlayer },
  props: {
  
  },
  data() {
    return {
      currencySymbol: '',
      info: {},
      dateRangeDesc: this.$t("common_0034"),
      winloseParams: {
        // StartDate: 20210101,
        // EndDate: 20210712,
        DateRangeType: 1,
      },
      orderParams: {
        // StartDate: 20210101,
        // EndDate: 20210712,
        TicketNo: this.$f7route.params.Id,
      },
      showWinlose: false,
      orderInfo: {},
      winloseInfo: {},
      totalBetAmount: 0,
      totalBalance: 0,
      totalWinlose: 0,
      totalBetCount: 0,
      dailyOrderCountLimit: 0,
      totalStatistics: 0,
      totalDeposit: 0,
      totalWithdrawal: 0,
      totalBonus: 0,
      estimatedCommissionToday: 0,
      link: "",
      timeIng : 0,
      maxTimeIng : 150,
      countDown: 150,
      checkFlag : 'no',
      //歌图转动属性
      rotationDegree : 0 ,
      intervalMusic :null,
      intervalTime : null,
      intervalOk : null,
      isPlaying: false,
      isOk:false,
      xinvalue: 2,
      //视频
      videoSrc: 'null',
      randomNumbers:[],
      ev0: true,
      ev1: true,
      ev2: true,
      ev3: true,
      ev4: true,
      providerGameURL:'',

      intervalId:null, // 用于存储 setInterval 的 ID
      isPaused:false, // 用于控制暂停和继续
    };
  },
  watch: {},
  computed: {
    rotationStyle() {
      return {
        transform: `rotate(${this.rotationDegree}deg)`
      };
    },
    ...mapGetters(["userInfo"]),
  },
  methods: {
    format(percentage) {
      return percentage === this.dailyOrderCountLimit ? "100%" : `${percentage}%`;
    },

    onPageInit() {
      // this.videoSrc = getStore("AboutUsMediaUrl")

      // if (Hls.isSupported()) {
      //   const video = this.$refs.myVideo;
      //   const hls = new Hls();
      //   hls.loadSource(this.videoSrc);
      //   hls.attachMedia(video);
      //   hls.on(Hls.Events.MANIFEST_PARSED, () => {
      //     // video.play();
      //   });
      // } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      //   video.src = this.videoSrc;
      //   video.addEventListener('canplay', () => {
      //     // video.play();
      //   });
      // }

    },

    // 开始计时器函数
    startInterval() {
      const self = this;
      if(self.countDown == 0){
        return;
      } 
      if (!self.intervalId) { // 防止重复启动
        self.intervalId = setInterval(() => {
          self.timeIng++;
          self.countDown--;
          if(self.timeIng == self.maxTimeIng){
            self.stopInterval();
            self.checkFlag = 'yes';
          }
        }, 100); // 每秒执行一次
      }
    },

    // 暂停计时器函数
    pauseInterval() {
      const self = this;

        clearInterval(self.intervalId); // 暂停
        self.intervalId = null;
        self.isPaused = true;
        // console.log("已暂停");
      
    },
                          
    // 继续计时器函数
    resumeInterval() {
      const self = this;
      self.startInterval(); // 重新启动
      self.isPaused = false;
      // console.log("继续执行");
      
    },
    stopInterval() {
      const self = this;
      clearInterval(self.intervalId); // 清除 interval
      self.intervalId = null;
      self.isPaused = false;
      // console.log("已结束");
    },


    loadVideo() {
      const self = this;
      const video = this.$refs.myVideo;
      video.autoplay = false;
      // 检查浏览器是否支持 hls.js
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(this.providerGameURL);
        hls.attachMedia(video);

        // 监听事件，视频元数据解析完毕后自动播放
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // video.play();
          this.$refs.myVideo.addEventListener('canplaythrough', () => {   // 检测视频加载完成，按钮可以播放
            this.isOk = true;
          });

        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // Safari浏览器原生支持 HLS
        video.src = this.providerGameURL;
        video.addEventListener('loadedmetadata', () => {
          video.play();
        });
      }
    },
    loadVideo2() {
      const video = this.$refs.myVideo2;
      video.autoplay = false;
      // 检查浏览器是否支持 hls.js
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(this.providerGameURL);
        hls.attachMedia(video);

        // 监听事件，视频元数据解析完毕后自动播放
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          //video.play();
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // Safari浏览器原生支持 HLS
        video.src = this.providerGameURL;
        video.addEventListener('loadedmetadata', () => {
          video.play();
        });
      }
    },
    handlePlay() {
      // if(!this.isOk){
      //   this.$f7.dialog.alert(this.$t("order_submission_0005"));
      //   this.handlePause();
      //   return;
      // }

      //禁止自动全屏
      this.$refs.myVideo.setAttribute('playsinline', '');
      this.$refs.myVideo.setAttribute('webkit-playsinline', '');
      this.$refs.myVideo2.setAttribute('playsinline', '');
      this.$refs.myVideo2.setAttribute('webkit-playsinline', '');

      this.isPlaying = true;
      this.startInterval();
      this.$refs.myVideo.play();
      this.$refs.myVideo2.play();
    },

    handlePause() {
      this.isPlaying = false;
      this.pauseInterval();
      this.$refs.myVideo.pause();
      this.$refs.myVideo2.pause();
    },

    startTask(){
      if (this.isPlaying) return;
      this.handlePlay();
    },

    setScore(){
      const self = this;
      //评分弹窗
      // var  tip = document.getElementById('setScore').innerHTML;
      var tip = `<div id="setScore" style="text-align: center;"><h3 style="margin-bottom: 5px;">`+this.$t("Order_0018")+`</h3><div style="margin-bottom: 20px;">`+this.$t("Order_0019")+`</div><div id="rate"></div></div>`;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog.create({
        title: this.$t(""),
        text: tip,
        cssClass: "dialog-logout",
        closeOnClickOutside: false,
        buttons: [
          {
            text: this.$t("common_0001"),
            cssClass: "dialog-ok",
            onClick: () => {
             self.requestConfirmOrder();
            },
          },
        ],
        on: {
          opened: () => {
            new Vue({
              el: '#rate',
              data: {
                rate: 3
              },
              template: `<van-rate v-model="rate" @click.stop :size="25" color="#ffd21e" void-icon="star" void-color="#eee"/>`
            });
          }
        }
      }).open();
    },

    requestConfirmOrder() {
      const self = this;
      const param = { data: JSON.stringify(self.orderParams) };
      self.$f7.params.dialog.closeByBackdropClick = false;
      let promtLoadingGame = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("Order_0017"),
          cssClass: "dialog-preloadorder",
        })
        .open();
      confirmorder(param).then((data) => {
        setTimeout(() => {
          self.$f7.params.dialog.closeByBackdropClick = true;
          promtLoadingGame.close();
          if (data.Code === "NoError") {
            //self.orderInfo = data.Data;
            if (data.Data != null) {
              if (data.Data.ExistsPendingOrder) {
                //Show success
                self.$f7.params.dialog.closeByBackdropClick = false;
                let promtLoadingGame = self.$f7.dialog
                  .create({
                    title: "",
                    text: this.$t("Order_0004"),
                    cssClass: "dialog-preloadorder",
                  })
                  .open();

                //1.5 sec later show have new order
                setTimeout(() => {
                  self.$f7.params.dialog.closeByBackdropClick = true;
                  promtLoadingGame.close();
                  this.orderParams.TicketNo = data.Data.TargetOrderTicketNo;
                  if (data.Data.ShowLuckyPopup) {
                    //show popup then reload ticket info
                    self.$f7.params.dialog.closeByBackdropClick = false;
                    self.$f7.dialog.open("#dialog-orderConfirm", true);
                  } else {
                    // dont show popup then reload ticket info
                    self.$f7.params.dialog.closeByBackdropClick = false;
                    let promtLoadingGame = self.$f7.dialog
                      .create({
                        title: "",
                        text: this.$t("Order_0005"),
                        cssClass: "dialog-preloadorder",
                      })
                      .open();
                    setTimeout(() => {
                      self.$f7.params.dialog.closeByBackdropClick = true;
                      promtLoadingGame.close();
                      this.getTargetOrder(this.orderParams.TicketNo);
                    }, 1500);
                  }
                }, 1500);
              } else {
                self.$f7.params.dialog.closeByBackdropClick = false;
                let promtLoadingGame = self.$f7.dialog
                  .create({
                    title: "",
                    text: this.$t("Order_0004"),
                    cssClass: "dialog-preloadorder",
                  })
                  .open();
                setTimeout(() => {
                  self.$f7.params.dialog.closeByBackdropClick = true;
                  promtLoadingGame.close();
                  // self.successNoshowbtn = false;
                  self.$f7router.navigate("/member-center/order-center/0/");
                }, 1500);
              }
            }
          } else if (data.Code === "BalanceNotEnough") {
            self.$f7.dialog.confirm(
              data.Data,
              this.$t("common_1025"),
              () => {
                self.$f7router.navigate("/member-center/deposit/");
              },
              () => {
                // self.$f7router.back();
              }
            );
          } else {
            //提示
            self.$f7.dialog.alert(data.Data);
          }
        }, 1800);
      });
    },
    getRandomNumbers() {
      for (let i = 0; i < 5; i++) {
        const randomNumber = Math.floor(Math.random() * 101); // 生成 0-100 的随机数
        this.randomNumbers.push(randomNumber);
      }
    },
    randomNumbersAdd(id){
      const self = this;
      var num;
      if (id == 0) {
        if(self.ev0){
          num=self.randomNumbers[0]+1
          self.ev0 = false;
          self.randomNumbers.splice(id, 1, num);
        }
      } else if (id == 1) {
        if(self.ev1){
          num=self.randomNumbers[id]+1
          self.ev1 = false;
          self.randomNumbers.splice(id, 1, num);
          
        }
      } else if (id == 2) {
        if(self.ev2){
          num=self.randomNumbers[id]+1
          self.randomNumbers.splice(id, 1, num);
          self.ev2 = false;
        }
      } else if (id == 3) {
        if(self.ev3){
          num=self.randomNumbers[id]+1
          self.ev3 = false;
          self.randomNumbers.splice(id, 1, num);

        }
      } else if (id == 4) {
        if(self.ev4){
          num=self.randomNumbers[id]+1
          self.ev4 = false;
          self.randomNumbers.splice(id, 1, num);
        }
      }
      
    },
    getTargetOrder(targetOrderTicketNo) {
      const self = this;
      const param = { data: JSON.stringify({ TicketNo: targetOrderTicketNo }) };
      getinitorderinfo(param).then((data) => {
        if (data.Code === "NoError") {
          self.orderInfo = data.Data;
        } else {
          //提示
          self.$f7.dialog.alert(data.Data);
        }
      });
    },
    selectInitOrderInfo(checkPopup) {
      const self = this;
      const param = { data: JSON.stringify(self.orderParams) };
      getinitorderinfo(param).then((data) => {
        if (data.Code === "NoError") {
          if (checkPopup == true) {
            if (data.Data.ShowLuckyPopup) {
              self.$f7.params.dialog.closeByBackdropClick = false;
              self.$f7.dialog.open("#dialog-orderConfirm", true);
            } else {
              self.orderInfo = data.Data;
            }
          } else {
            self.orderInfo = data.Data;
          }
          self.providerGameURL = data.Data.ProviderGameURL;
          self.loadVideo();
          self.loadVideo2();
        } else {
          //提示
          self.$f7.dialog.alert(data.Data);
        }
      });
    },
    backWinloserp() {
      this.$f7router.navigate("/member-center/order-center/2/");
    },
    getDateRangeDesc() {
      const self = this;
      switch (self.winloseParams.DateRangeType.toString()) {
        case "1":
          return this.$t("common_0034");
        case "2":
          return this.$t("common_0036");
        case "3":
          return this.$t("common_0038");
        case "11":
          return this.$t("common_0035");
        case "12":
          return this.$t("common_0037");
        case "13":
          return this.$t("common_0039");
        default:
          return "--";
      }
    },
    selectMemberWinloseRP() {
      // this.$f7router.navigate(`/member-center/order-submission/`);
      const self = this;
      self.showWinlose = false;
      const param = { data: JSON.stringify(self.winloseParams) };
      getmemberwinloserp(param).then((data) => {
        if (data.Code === "NoError") {
          self.winloseInfo = data.Data;
          self.totalBetAmount = self.winloseInfo[0].TotalBetAmount;
          self.totalBalance = self.winloseInfo[0].TotalBalance;
          self.totalWinlose = self.winloseInfo[0].TotalWinlose;
          self.totalBetCount = self.winloseInfo[0].TotalBetCount;
          self.dailyOrderCountLimit = self.winloseInfo[0].DailyOrderCountLimit;

          self.showWinlose = true;
        }
      });
    },
    closeDialog(targetId) {
      //this.$f7.$(".dialog-backdrop").css("background", "transparent");
      this.$f7.params.dialog.closeByBackdropClick = true;
      this.$f7.dialog.close(`#${targetId}`);
      this.$f7.emit("onCloseDialog", targetId);
      //console.log(this.orderParams.TicketNo);
      this.selectMemberWinloseRP();
      this.getTargetOrder(this.orderParams.TicketNo);
    },
  },
  created() {
    this.selectInitOrderInfo(true);
    this.selectMemberWinloseRP();
    this.currencySymbol = getStore('currencySymbol');
    this.getRandomNumbers();  //生成5个随机数字
  },
  mounted() {
    this.$refs.myVideo.addEventListener('canplaythrough', () => {   // 检测视频加载完成，按钮可以播放
      this.isOk = true;
    });

  },
  beforeDestroy() {
  },
};
</script>
<style lang="less" scoped>
.order-submission {
  --f7-theme-color: #DA291C;
  color: #464646;
  .top {
    background: #fff;
    // border: 1px solid #bfb6b6;
    // border-radius: 4px;
    //margin: 10px;
    font-size: 14px;
    .stat-item {
      height: 80px;
    }
    .video{
      margin-top: 12px;
       /* 根据需要选择颜色 */

      .video-container {
        // background-color: black;
      }

      /* 背景虚化的视频 */
      .blurred-background-video {
        position: absolute;
        width: 100%;
        height: 280px;
        object-fit: cover;
        filter: blur(5px); /* 背景视频模糊 */
        z-index: 1;
      }

      /* 前景的视频 */
      .foreground-video {
        position: absolute;
        width: 100%;
        height: 280px;
        z-index: 2;
      }
    }
    .cz{
      padding: 5px 15px;
      height: 20px;
      line-height: 20px;
      img{
        margin-right: 5px;
        
      }
      span{
        margin-right: 20px;
        display: inline-flex;
      }
    }
    .statistics {
      position: relative;
      width: 96%;
      margin: auto;
      background: #fafafa;
      border-radius: 5px;
      text-align: center;
      margin-top: 10px;
      border: 1px solid #1b2a3b10;
      .list-type-ico {
        position: absolute;
        top: -13px;
        left: 10px;
      }
      .ico-loc {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      .p-loc {
        margin: 0px 0px 0px 35px;
        font-size: 16px;
        font-weight: 600;
      }
      .p-mobile {
        margin: 5px 0px 0px 35px;
        font-size: 14px;
        color: #050505;
      }
      .p-img {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 5px;
        left: 5px;
        border: 1px solid #e1e1e1;
        border-radius: 4px;
      }
      .p-ticketno {
        width: 96%;
        line-height: 26px;
        display: inline-block;
        margin-top: 0px;
        text-align: left;
        margin-left: 40px;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: -3px;
      }
      .p-ts {
        font-size: 12px;
        width: 90%;
        display: inline-block;
        text-align: left;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 6px;
      }
      .p-item-detail {
        width: calc(100% - 85px);
        margin-left: auto;
        text-align: left;
        .p-item-name {
          font-family: Cairo;
          font-size: 15px;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .p-item-price {
          margin-top: 8px;
          color: #ff0000;
        }
      }
      li {
        width: 100%;
        display: inline-block;
        margin-top: 10px;
        text-align: left;
        //margin-left: 10px;
        margin-bottom: 10px;
      }
      .li-right {
        text-align: right;
        margin-left: unset;
        //margin-right: 10px;
        .small {
          font-size: 12px;
        }
      }
      .li-center {
        font-weight: bold;
        text-align: center;
        margin-left: unset;
        //margin-right: 10px;
      }
      .li-step {
        margin-left: unset;
        // display:inline-block;
        // margin-bottom: 10px
      }
      .title {
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }
      .amount {
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }
      .desc {
        border-top: 1px solid #e0dbdb;
        margin-top: 10px;
        .p1 {
          margin: 10px;
          font-weight: bold;
        }
        .p2 {
          margin-left: 10px;
          margin-right: 10px;
          padding-bottom: 10px;
          word-wrap: break-word;
        }
      }
      .success{
        position: absolute;
        right: 20px;
        top: 30px;
      }
    }
    .title{
      position: relative;
      padding-left: 15px;
      font-weight: bold;
      font-size: 16px;
    }
    .title::before{
      content: '';
      position: absolute;
      top: 3px;
      left: 5px;
      transform: translateX(-50%);
      width: 3px;
      height: 15px;
      background-color: var(--f7-THEME-color);
    }
   .introduction {
    // border: 1px solid #e5e5e5;
    padding-top: 5px;
    margin: 10px 0;

    .introductionone {
      padding: 5px;
      height: 150px;
      // border-bottom: 1px solid #e5e5e5;

      img {
        border: 0;
        border-radius: 5px;
        width: 100px;
        position: absolute;
      }

      span {
        display: block;
        padding-left: 115px;
        margin: 4px 0;
      }

      .nameSpan {
        font-weight: bold;
        color: #000;
        display: block;
        padding-left: 115px;
        margin: 0 0 3px 0;
        white-space: nowrap;
        /* 禁止换行 */
        overflow: hidden;
        /* 超出部分隐藏 */
        text-overflow: ellipsis;
        /* 超出部分显示省略号 */
        margin-top: 7px;

      }
    }
  }
    .info {
      width: 90%;
      margin: 10px auto;
      div {
        background: #f1f1f1;
        border-radius: 5px;
        height: 28px;
        line-height: 28px;
        // margin-top: 6px;
        position: relative;
        span:nth-child(1) {
          float: left;
          margin-left: 10px;
        }
        span:nth-child(2) {
          color: #002fb3;
        }
        .refer {
          background: linear-gradient(#decabc, #b19785);
          color: #000;
          height: 26px;
          border-radius: 4px;
          line-height: 28px;
          width: 50px;
          display: inline-block;
          text-align: center;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }
      .list ul:before {
        background-color: transparent;
      }
      .list ul:after {
        background-color: transparent;
      }
      .list .item-content {
        display: flex;
        justify-content: space-around;
      }
    }
    .line {
      width: 95%;
      margin: auto;
      margin-top: 15px;
      border-top: 1px solid #1b336e;
    }
    .list {
      margin: auto;
    }
    .share {
      width: 90%;
      background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;
      .sharedesc {
        width: 96%;
        color: #9cfaff;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .sharebutton {
        width: 80px;
        height: 28px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #000;
        background: linear-gradient(#decabc, #b19785);
        border-radius: 15px;
      }
    }
    .copy {
      width: 95%;
      //background: #2d2d2d;
      background: var(--f7-button-bg-color);
      color: var(--f7-button-text-color);
      border-radius: 7px;
      height: 40px;
      line-height: 30px;
      margin: 25px auto;
      position: relative;
      // padding-left: 10px;
      .sharedesc {
        color: #9cfaff;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .copybutton {
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        display: inline-block;
        border-radius: var(--f7-list-inset-border-radius);
        font-size: 16 px;
      }
    }
    .noClick{
      background: #ccc;
    }
  }
  
  .middle {
    border-top: 1px solid #bfbfbf;
    .wallet {
      background: url("../../../static/images/yt999/referrer/icon-up.png") bottom no-repeat, url("../../../static/images/yt999/referrer/highlight.png") bottom #efefef no-repeat;
      background-size: 100%;
      padding-top: 15px;
      width: 50%;
      position: relative;
      > div {
        margin-bottom: 15px !important;
      }
      color: #101010;
      .rebtn {
        background: linear-gradient(#decabc, #b19785);
        border-radius: 4px;
        width: 82%;
        height: 30px;
        line-height: 30px;
        color: #000;
        margin: auto;
      }
    }
    .rightafter::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 2px;
      height: 128px;
      background: url("../../../static/images/yt999/navafter.png") bottom no-repeat;
      background-size: 100% 100%;
    }
  }

  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    color: #101010;
    .s-title {
      font-size: 12px;
    }
  }
}
//音乐播放器
.music{
  ul li{
    display: block;
  }
  .musicPic{
    height: 150px;
    width: 150px;
    margin: 0 auto;
    position: relative;
    background: url("../../../static/images/yt999/music/bg1.png");
    background-size: 100% 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    img{
      width:80px ;
      width:80px ;
    }
  }

  .bg2{
    height: 80px;
    position: absolute;
    top: 20px;
    right: -50px;
  }
  .musiclogo{
    border-radius: 40px;
  }
  .rotating-box {
    
    transition: transform 0.5s;
  }
  .li-right{
    margin: 0!important;
    p{
      margin: 0;
      padding-bottom: 5px;
      text-align: center;
    }
    p:nth-child(1){
      font-size: 15px;
      font-weight: bold;
    }
    p:nth-child(2){
      color: rgb(135 130 130);
    }
  }
  .audio-player {
    display: flex;
    align-items: center;
    .audio{
      width: 100%;
      height: 40px;
    }
  }

}
.audio{
  
}
.van-rate {
  z-index: 1001 !important;
}
.vue-sound__extern-wrapper{
  display: none;
}
// video::-webkit-media-controls-timeline {
//   display: none;
// }
video {
}

</style>
