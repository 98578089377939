<template>
  <f7-page no-toolbar no-swipeback name="member-info" class="page-member-info">
    <f7-navbar :title="$t('member_info_0001')" back-link :no-hariline="true"></f7-navbar>
    <div class="buttons-tab buttons-deposit">
      <f7-link tab-link="#tab-personal" data-route-tab-id="tab-personal" tab-link-active>{{ $t("member_info_0001") }}</f7-link>
      <f7-link tab-link="#tab-identity" data-route-tab-id="tab-identity">{{ $t("member_info_0025") }}</f7-link>
    </div>
    <f7-tabs animated>
      <f7-tab id="tab-personal" class="page-content" tab-active>
        <f7-block class="block-member-info" v-if="loadStatus">
          <div class="form">
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0002") }}</div>
                <div class="item-input-wrap">
                  <input type="text" readonly :value="userInfo.UserName.split('.')[1]" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0003") }}</div>
                <div class="item-input-wrap">
                  <input type="text" :readonly="userInfoStatus.SureName" :placeholder="$t('member_info_0004')" v-model="userInfoParam.SureName" />
                </div>
              </div>
            </div>
            <!-- <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t('member_info_0005') }}</div>
            <div class="item-input-wrap">
              <input
                    type="text"
                    readonly
                    :placeholder="$t('member_info_0006')"
                    :value="userInfoParam.Birthday|time('YYYY-MM-DD')"
                  >
            </div>
          </div>          
        </div> -->
            <div class="form-item" v-show="userInfoStatus.Mobile == false">
              <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                <select v-model="CountryCodeID">
                  <option v-for="item in CountryCodeList" :key="item.CountryID" :value="item.CountryID">{{ item.NameDesc }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title">{{ $t("register_0020") }}</div>
                    <div v-if="CountryCodeList.length > 0" class="item-after atm">{{ CountryCodeList[0].NameDesc }}</div>
                    <div v-else class="item-after atm" style="display: unset">--</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="form-item" v-show="userInfoStatus.Mobile == true && userInfoParam.CountryName != ''">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("register_0020") }}</div>
                <div class="item-input-wrap">
                  <input type="text" readonly v-model="userInfoParam.CountryName" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0007") }}</div>
                <div class="item-input-wrap">
                  <input v-if="userInfoStatus.Mobile == false" type="text" :placeholder="$t('member_info_0008')" v-model="userInfoParam.Mobile" />
                  <input v-else type="text" :placeholder="$t('member_info_0008')" readonly v-model="formattedMobile" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0011") }}</div>
                <div class="item-input-wrap">
                  <input type="text" :placeholder="$t('member_info_0012')" :readonly="userInfoStatus.Email" v-model="userInfoParam.Email" />
                  <!-- <span class="input-clear-button"></span> -->
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0023") }}</div>
                <div class="item-input-wrap">
                  <input type="text" :placeholder="$t('member_info_0024')" :readonly="userInfoStatus.Address" v-model="userInfoParam.Address" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0015") }}</div>
                <div class="item-input-wrap">
                  <input type="text" readonly :value="userInfo.LastLoginTs | time_local" />
                </div>
              </div>
            </div>
            <div class="form-item">
              <div class="item-inner">
                <div class="item-title item-label">{{ $t("member_info_0050") }}</div>
                <div class="item-input-wrap">
                  <input type="text" readonly :value="userInfo.RegisterTs | time_local" />
                </div>
              </div>
            </div>
            <!-- <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t('member_info_0016') }}</div>
            <div class="item-input-wrap">
              <input type="text" readonly :value="userInfo.LastLoginIP">
            </div>
          </div>          
        </div> -->
          </div>
        </f7-block>
        <div class="list inset button-save">
          <ul>
            <li>
              <a href="#" class="list-button color-black" @click="updateUserInfoEvent">{{ $t("member_info_0017") }}</a>
            </li>
          </ul>
        </div>
      </f7-tab>
      <f7-tab id="tab-identity" class="page-content">
        <f7-block>
          <div class="status-content">
            <div class="idStatus">{{ $t("member_info_0026") }}</div>
            <div v-show="MemberDocumentsInfo.VerifyStatus == 'NotVerify'" class="pendSubmission">{{ $t("member_info_0027") }}</div>
            <div v-show="MemberDocumentsInfo.VerifyStatus == 'Verifying'" class="underReview">{{ $t("member_info_0028") }}</div>
            <div v-show="MemberDocumentsInfo.VerifyStatus == 'Verified'" class="approved">{{ $t("member_info_0029") }}</div>
            <div v-show="MemberDocumentsInfo.VerifyStatus == 'VerifyFailed'" class="notApproved">{{ $t("member_info_0030") }}</div>
          </div>
          <div v-show="MemberDocumentsInfo.VerifyStatus == 'VerifyFailed'" class="remark-content">
            <div class="remark-title">{{ $t("member_info_0031") }}</div>
            <div class="remark-text">{{ MemberDocumentsInfo.RemarkForMember || "--" }}</div>
          </div>
          <div v-show="MemberDocumentsInfo.VerifyStatus == 'NotVerify' || MemberDocumentsInfo.VerifyStatus == 'VerifyFailed'" class="upload-content">
            <div class="upload-box">
              <van-uploader upload-icon="photo" v-model="filesArr[0].imageFiles" :max-count="1" multiple :max-size="5 * 1024 * 1024" accept="image/*" preview-size="100px" @oversize="onOversize" />
              <span>{{ $t("member_info_0032") }}</span>
            </div>
            <div class="upload-box">
              <van-uploader upload-icon="photo" v-model="filesArr[1].imageFiles" :max-count="1" multiple :max-size="5 * 1024 * 1024" accept="image/*" preview-size="100px" @oversize="onOversize" />
              <span>{{ $t("member_info_0033") }}</span>
            </div>
            <div class="upload-box">
              <van-uploader upload-icon="photo" v-model="filesArr[2].imageFiles" :max-count="1" multiple :max-size="5 * 1024 * 1024" accept="image/*" preview-size="100px" @oversize="onOversize" />
              <span>{{ $t("member_info_0034") }}</span>
            </div>
          </div>
          <div v-if="MemberDocumentsInfo.VerifyStatus == 'NotVerify' || MemberDocumentsInfo.VerifyStatus == 'VerifyFailed'" class="tip-content">
            <div class="tip-title">{{ $t("member_info_0035") }}</div>
            <div>{{ $t("member_info_0036") }}</div>
            <div>{{ $t("member_info_0037") }}</div>
            <div>{{ $t("member_info_0038") }}</div>
            <div>{{ $t("member_info_0039") }}</div>
          </div>
          <div v-else class="tip-content">
            <div class="tip-title">{{ $t("member_info_0035") }}</div>
            <div v-show="MemberDocumentsInfo.VerifyStatus == 'Verified'">{{ $t("member_info_0040") }}</div>
            <div v-show="MemberDocumentsInfo.VerifyStatus == 'Verifying'">{{ $t("member_info_0041") }}</div>
            <div>{{ $t("member_info_0042") }}</div>
          </div>
          <div v-show="MemberDocumentsInfo.VerifyStatus == 'NotVerify' || MemberDocumentsInfo.VerifyStatus == 'VerifyFailed'" class="submit-content">
            <span class="submitButton" @click="updateUserDocument">{{ $t("member_info_0043") }}</span>
          </div>
        </f7-block>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script>
import { getUserInfo, updateUserInfo, countryCodeListGet, uploadmemberdocumentsinfo } from "../../../axios/api";
import { ImagePreview } from "vant";
import $upload from "@/service/upload";

export default {
  components: { [ImagePreview.Component.name]: ImagePreview.Component },
  props: {},
  data() {
    return {
      userInfo: {},
      loadStatus: false,
      userInfoParam: {
        SureName: "",
        Mobile: "",
        Email: "",
        Wechat: "",
        Birthday: 0,
        QQ: "",
        Address: "--",
        CountryCodeID: 0,
        CountryName: "",
      },
      userInfoStatus: {
        SureName: false,
        Mobile: false,
        Email: false,
        Wechat: false,
        QQ: false,
        Birthday: false,
        Address: false,
      },
      MemberDocumentsInfo: {},
      CountryCodeID: -1,
      CountryCodeList: [],
      filesArr: [
        { name: "image_a", imageFiles: [] },
        { name: "image_b", imageFiles: [] },
        { name: "image_c", imageFiles: [] },
      ],
      EnableMemberDocumentsInfoVerify: "",
      MemberDocumentsVerifyStatus: "",
    };
  },
  watch: {},
  computed: {
    formattedMobile: {
      get() {
        // 当读取数据时应用过滤器
        return this.formatPhoneNumber(this.userInfoParam.Mobile);
      },
      set(value) {
        // 当写入数据时更新原始数据
        this.userInfoParam.Mobile = value;
      },
    },
  },
  methods: {
    getUserInfoEvent() {
      const self = this;
      getUserInfo().then((data) => {
        if (data.Code === "NoError") {
          self.loadStatus = true;
          self.userInfo = data.Data;
          self.userInfoParam = {
            SureName: data.Data.SureName,
            Mobile: data.Data.Mobile,
            Email: data.Data.Email,
            Wechat: data.Data.Wechat,
            Birthday: data.Data.Birthday,
            QQ: data.Data.QQ,
            Address: data.Data.Address,
            CountryName: data.Data.CountryName,
          };
          self.MemberDocumentsInfo = data.Data.MemberDocumentsInfo;
          self.EnableMemberDocumentsInfoVerify = data.Data.EnableMemberDocumentsInfoVerify;
          self.MemberDocumentsVerifyStatus = data.Data.MemberDocumentsVerifyStatus;
          self.initUserStatus(data.Data);
        }
      });
    },
    formatPhoneNumber(value) {
      // 这里是您之前定义的过滤器逻辑
      if (!value) return "";
      value = value.toString();

      if (value.length >= 4) {
        const lastFourChars = value.substring(value.length - 4);
        return `******${lastFourChars}`;
      } else {
        return `******${value}`;
      }
    },
    initUserStatus(data) {
      if (data.SureName) {
        this.userInfoStatus.SureName = true;
      }
      if (data.Mobile) {
        this.userInfoStatus.Mobile = true;
      }
      if (data.Email) {
        this.userInfoStatus.Email = true;
      }
      if (data.Wechat) {
        this.userInfoStatus.Wechat = true;
      }
      if (data.QQ) {
        this.userInfoStatus.QQ = true;
      }
      if (data.Birthday && data.Birthday != "1900-01-01T00:00:00") {
        this.Birthday = true;
      }
      if (data.Address) {
        this.userInfoStatus.Address = true;
      }
    },
    getCountryCodeList() {
      const self = this;
      countryCodeListGet().then((data) => {
        if (data.Code == "NoError") {
          this.CountryCodeList = data.Data;
          if (this.CountryCodeList.length > 0) {
            this.CountryCodeID = this.CountryCodeList[0].CountryID;
          }
        }
      });
    },
    updateUserInfoEvent() {
      const self = this;
      let sureName = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;
      let iphoneNumber = /^\d{5,18}$/;
      let QQ = /^[1-9][0-9]{5,10}$/;
      let wechat = /^[a-zA-Z0-9]{1}[-_a-zA-Z0-9]{5,19}$/;
      let Email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      let param = {
        SureName: "",
        Mobile: "",
        Email: "",
        Wechat: "",
        Birthday: 0,
        QQ: "",
        Address: "--",
        CountryCodeID: 0,
      };

      if (!self.userInfoStatus.SureName) {
        // if ( !sureName.test(self.userInfoParam.SureName) && self.userInfoParam.SureName )
        // {
        //   self.$f7.dialog.alert(this.$t('member_info_0018'), this.$t('common_0004'));
        //   return false;
        // }
        param.SureName = self.userInfoParam.SureName;
      }

      if (!self.userInfoStatus.Mobile) {
        if (self.CountryCodeID === -1 || self.CountryCodeID === 0) {
          self.$f7.dialog.alert(this.$t("register_0021"), this.$t("common_0004"));
          return false;
        }
        if (!iphoneNumber.test(self.userInfoParam.Mobile) && self.userInfoParam.Mobile) {
          self.$f7.dialog.alert(this.$t("member_info_0019"), this.$t("common_0004"));
          return false;
        }
        param.Mobile = self.userInfoParam.Mobile;
        param.CountryCodeID = self.CountryCodeID;
      }
      // if (!self.userInfoStatus.QQ) {
      //   if (!QQ.test(self.userInfoParam.QQ) && self.userInfoParam.QQ) {
      //     self.$f7.dialog.alert("this.$t('member_info_0020')", this.$t('common_0004'));
      //     return false;
      //   }
      //   param.QQ = self.userInfoParam.QQ;
      // }
      if (!self.userInfoStatus.Email) {
        if (!Email.test(self.userInfoParam.Email) && self.userInfoParam.Email) {
          self.$f7.dialog.alert(this.$t("member_info_0021"), this.$t("common_0004"));
          return false;
        }
        param.Email = self.userInfoParam.Email;
      }
      if (!self.userInfoStatus.Address) {
        if (self.userInfoParam.Address == "") {
          param.Address = "--";
          // self.$f7.dialog.alert(this.$t("member_info_0024"), this.$t("common_0004"));
          // return false;
        }
        param.Address = self.userInfoParam.Address;
      }

      // param.Birthday = self.userInsfoParam.Birthday;
      // if (!self.userInfoStatus.Wechat) {
      //   if (
      //     !wechat.test(self.userInfoParam.Wechat) &&
      //     self.userInfoParam.Wechat
      //   ) {
      //     self.$f7.dialog.alert("this.$t('member_info_0022')", this.$t('common_0004'));
      //     return false;
      //   }
      //   param.Wechat = self.userInfoParam.Wechat;
      // }
      const d = { data: JSON.stringify(param) };
      self.$f7.progressbar.show("blue");
      updateUserInfo(d).then((data) => {
        self.$f7.progressbar.hide();
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"),function(){
          self.$f7router.navigate("/member-center/");
        });
        
        if (data.Code === "NoError") {
          self.getUserInfoEvent();
          if (self.EnableMemberDocumentsInfoVerify == true) {
            //EnableMemberDocumentsInfoVerify 这个是告诉你这个商户是不是需要会员提交证件资料，如果他是false，说明商户不需要会员提交证件资料 则不用做下面这些判断
            if (self.MemberDocumentsVerifyStatus == "NotVerify") {
              self.$f7.dialog.confirm(
                this.$t("member_info_0048"),
                this.$t("common_0004"),
                () => {
                  self.$f7.$(".dialog-backdrop").css("background", "transparent");
                  self.$f7router.navigate("/member-center/member-info/tab-identity/");
                },
                () => {
                  //self.$f7router.back();
                }
              );
            } else if (self.MemberDocumentsVerifyStatus == "VerifyFailed") {
              self.$f7.dialog.confirm(
                this.$t("member_info_0049"),
                this.$t("common_0004"),
                () => {
                  self.$f7.$(".dialog-backdrop").css("background", "transparent");
                  self.$f7router.navigate("/member-center/member-info/tab-identity/");
                },
                () => {
                  //self.$f7router.back();
                }
              );
            }
          }

        }
      });
    },
    updateUserDocument() {
      const self = this;
      if (self.filesArr[0].imageFiles.length == 0) {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(this.$t("member_info_0045"));
        return false;
      }
      if (self.filesArr[1].imageFiles == 0) {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(this.$t("member_info_0046"));
        return false;
      }
      if (self.filesArr[2].imageFiles == 0) {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(this.$t("member_info_0047"));
        return false;
      }
      $upload.uploadImg(self.filesArr).then((data) => {
        self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
        self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        if (data.Code === "NoError") {
          self.getUserInfoEvent();
        }
      });
    },
    onOversize() {
      this.$f7.dialog.alert(this.$t("member_info_0044"), this.$t("common_0004") );

    },
    imgPerview(url) {
      ImagePreview([url]);
    },
  },
  created() {
    this.getCountryCodeList();
    this.getUserInfoEvent();
  },
  mounted() {},
  updated() {},
};
</script>

<style lang="less" scoped>
.page-member-info {
  --f7-page-toolbar-bottom-offset: 0;

  .buttons-deposit {
    height: var(--f7-px-50);
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);
    //background-color: #0C1939;

    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: #808080;

      i {
        margin-top: var(--f7-padding-size-2);
        font-size: var(--f7-px-32);
      }

      span {
        font-size: var(--f7-px-14);
        margin-left: var(--f7-margin-size-0);
      }
    }

    a.tab-link:after {
      content: "";
      //background: url(../static/images/yt999/navafter.png) no-repeat;
      width: var(--f7-width-size-2);
      height: var(--f7-height-size-pt100);
      position: absolute;
      right: var(--f7-size-ps-0);
      top: var(--f7-size-ps-0);
      background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
    }

    a.tab-link:last-child:after {
      height: var(--f7-height-size-0);
    }

    a.tab-link.tab-link-active {
      color: var(--f7-THEME-color);
      border-bottom: 1px solid var(--f7-THEME-color);

      i.iconfont {
        color: var(--f7-color-blue-active);
      }
    }
  }
  .status-content {
    display: grid;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;

    .idStatus {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .pendSubmission {
      color: #b57b24;
    }

    .underReview {
      color: #704785;
    }

    .approved {
      color: #415ec5;
    }

    .notApproved {
      color: #cd3434;
    }
  }

  .remark-content {
    margin: 10px 0px;
    border: 2px solid #e5e5e5;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    .remark-title {
      position: absolute;
      background-color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      width: 80px;
      text-align: center;
      top: -10px;
    }

    .remark-text {
      font-size: 14px;
      color: #cd3434;
      line-height: 20px;
      text-align: center;
    }
  }

  .upload-content {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    .upload-box {
      display: grid;
      text-align: center;
    }

    ::v-deep .van-uploader {
      position: relative;
      display: inline-block;
    }

    ::v-deep .van-uploader__wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    ::v-deep .van-uploader__upload {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin: 0 0.21333rem 0.21333rem 0;
      background-color: #f7f7f7;
      border: 1px solid #e8e8e8;
    }

    ::v-deep .van-uploader__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      cursor: pointer;
      opacity: 0;
    }

    ::v-deep .van-uploader__upload-icon {
      color: #c6c6c6;
      font-size: 34px;
    }

    ::v-deep .van-image {
      position: relative;
      display: inline-block;
    }
  }

  .tip-content {
    border-top: 2px solid #e5e5e5;
    margin: 10px 0;
    padding: 20px 0px 0px 0px;

    .tip-title {
      color: var(--f7-THEME-color);
      font-size: 14px;
      margin: 10px 0px;
    }
  }

  .submit-content {
    margin: 10px 0px;

    .submitButton {
      color: var(--f7-button-text-color);
      font-weight: bold;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      display: inline-block;
      background-color: var(--f7-THEME-color);
      border-radius: var(--f7-list-inset-border-radius);
      font-size: 17px;
    }
  }

  .block-member-info {
    margin: 5px 0 10px;
    padding: 0 10px;

    .form {
      .form-item {
        .atm {
          font-size: 13px;
          background: #fafafa;
          border: 1px solid #1b2a3b10;
          padding: 10px;
          border-radius: 6px;
          margin: 2px 0 10px;
          max-width: 100%;
          color: #000;
        }

        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }

          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;

            input {
              width: 100%;
            }
          }

          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
  }

  .button-save {
    margin: 10px 12px;

    ul {
      background: var(--f7-button-bg-color);

      li {
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>
